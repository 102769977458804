.title{
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.name{
    display: flex;
    justify-content: center;
    margin-bottom: 2000px;
}